@import "../../global.scss";

.works {
  // background-color: crimson;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  .headingDesign{
    margin: 20px;
    @include mobile{
      // background-color: aqua;
      height: 60px;

    }
  }
  .iframeDesign1{
    display: flex;
    align-items: center;
    @include mobile{
      display: flex;
      flex-direction: column;
      width:300px;
      height:400px;
      // background-color: rgb(154, 255, 255);
    }
  

    .iframeDesign{
      margin: 20px;
    }
  }
  

}