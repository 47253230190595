@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;


  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    @include mobile {
      margin-left: 50px;
      margin-bottom: 30px;
    }
    .slick-slider{
      overflow: hidden;
      margin-top: 50px;
    }
    .slick-dots{
      margin: 20px;
      

    }
    
    @include mobile {
      display: flex;
      justify-content: center;
    }



    img {
      height: 100%;
      width: 60%;
      margin-left: 100px;
      margin-top: 50px;
      border-radius: 10%;
      @include mobile {
        margin: 30px;

      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .div5Design{
      margin-top: 40px;
     text-align: center;
     font-size: 14px;

    }

    h2 {
      font-size: 30px;
      margin-bottom: 20px;
      margin-top: 30px;
    }


    .nameDesign{
      margin-top: 10px;
      font-weight: 300;
      font-size: 13px;
    }
    .bankDesign{
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center ;
      margin-left: 100px;

    .bankDesign1{
      margin-top: 10px;
      font-weight: 600;
      font-size: 12px;
    }
    .bankDesign2{
      margin-top: 10px;
      font-weight: 600;
      font-size: 12px;
    }
  }


    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input {
        width: 300px;
        height: 30px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 20px;
        }
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 100px;
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      span {
        color: green;
      }
    }
  }


  .last{
   flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile {
      margin-top: 30px;
    }
    .imgDesi{
      margin-top: 20px;
      height: 300px;
      width: 250px;
      
    }

  }
}