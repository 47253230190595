@import "../../global.scss";

.footerD {
    margin-top: 50px;
    padding-top: 40px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(195, 238, 224);
    border-radius: 20% 20% 0px 0px;

   
    

    ul {

        list-style: none;
        font-size: 20px;
        font-weight: 500;
        color: black;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile{
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            font-weight: 400;

          }


        li {
            margin-bottom: 30px;
            margin-right: 20px;

            a {
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover {
                font-weight: 800;
            }
        }
    }
    .spandiv{
        font-weight: 300;
        font-size: small;
        font-style: italic;
        margin-bottom: 50px;
    }
}