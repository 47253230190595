@import "../../global.scss";

.intro {
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;

    @include mobile {}


    .imgContainer {
      width: 700px;
      height: 700px;
      // background-color: crimson;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;

      @include mobile {
        align-items: flex-start;
        height: 500px;

      }

      img {
        height: 90%;

        @include mobile {
          height: 100%;
        }
      }
    }
  }

  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      // padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        align-items: center;
        margin: 20px;
      }

      .salDesign {
        position: absolute;
        top: 110px;
        right: 30px;
        font-size: 13px;
      }

      .salDesign2 {
        position: absolute;
        top: 130px;
        right: 30px;
        font-size: 13px;

      }

      h1 {
        font-size: 60px;
        margin: 10px 0;

        @include mobile {
          font-size: 40px;
        }
      }

      h2 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 600;
      }

      h3 {
        margin-top: 20px;
        font-size: 30px;

        @include mobile {
          margin-top: 60px;
          font-size: 25px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }
      }


      .mainDivDesign {
        display: flex;
        flex-direction: column;
        align-items: flex-end; /* Align items to the end on the cross axis */
        justify-content: flex-end; /* Align items to the end on the main axis */
        margin-right: 40px;
        margin-top: 70px;
        .lastDesign{
          font-style: italic;
          margin-top: 10px;
          font-weight: 600;
        }
      }
    }

    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}